.rolemanagement {
    height: 100%;
    display: flex;
    flex-direction: column;

    .rolemanagement-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 61px;
        padding-bottom: 20px;

        .rolemanagement-title {
            font-size: 22px;
            font-weight: bold;
            line-height: 25px;

            .badge {
                margin-left: 5px;
            }
        }
        .rolemanagement-toolbar {
            flex: 0 1 665px;
            display: flex;

            .rolemanagement-toolbar-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                width: 32px;
                height: 32px;
                margin-right: 10px;
            }

            .input-wrapper {
                flex: 1 1;
                position: relative;

                .fa-search::before {
                    position: absolute;
                    top: 10px;
                    left: 5px;
                }

                input {
                    height: 32px;
                    width: 100%;
                    border: 1px solid var(--arxs-border-shadow-color);
                    border-radius: 5px;
                    text-indent: 20px;
                }
            }
        }
    }

    .rolemanagement-details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-top: 40px;

        .rolemanagement-details-empty {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--arxs-secondary-color);

            .rolemanagement-details-empty-icon {
                i {
                    font-size: 37px;
                }
            }
            .rolemanagement-details-empty-text {
                padding-top: 52px;
                padding-bottom: 52px;
                font-size: 14px;
                font-weight: bold;
            }

            .rolemanagement-details-empty-action {
                display: flex;
                align-items: center;
            }

            .rolemanagement-details-empty-action-create {
                background-color: var(--arxs-create-color);
                height: 32px;
                border-radius: 4px;
                border: 0px;
                font-size: 12px;
                color: white;
                padding-left: 15px;
                padding-right: 15px;

                i {
                    padding-left: 6px;
                }
            }

            .rolemanagement-details-empty-action-create:hover {
                cursor: pointer;
            }
        }

        .Collapsible {
            padding-bottom: 10px;
            
            .Collapsible__trigger::after {
                bottom: 17px !important;
            }

            .Collapsible__trigger {
                display: block;
                position: relative;
                padding: 10px;
                color: var(--arxs-grey-base);

                cursor: pointer;

                border-bottom: 1px solid var(--arxs-nav-border-color);

                .rolemanagement-details-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    i {
                        font-size: 16px;
                        color: var(--arxs-secondary-color);
                    }

                    .rolemanagement-details-header-left {
                        display: flex;
                        flex-direction: row;

                        .rolemanagement-details-header-name {
                            padding-right: 10px;
                            font-size: 18px;
                            line-height: 21px;
                            font-weight: normal;
                        }
                        .rolemanagement-details-header-badge {
                            padding-right: 20px;
                        }
                        .rolemanagement-details-header-rename {
                            padding-right: 10px;
                        }
                    }
                    .rolemanagement-details-header-right {
                        display: flex;
                        flex-direction: row;

                        .rolemanagement-details-header-delete {
                            padding-left: 10px;
                        }
                        .rolemanagement-details-header-rights {
                            padding-right: 30px;
                            padding-left: 10px;
                        }
                    }
                }
            }

            .Collapsible__trigger::after {
                position: absolute;
                right: 20px;

                font-family: "Font Awesome 6 Pro";
                font-size: 10px;
                font-weight: 400;
                content: "\f078";

                color: var(--arxs-primary-inactive-color);
            }

            .Collapsible__trigger:hover::after {
                color: var(--arxs-primary-color);
            }

            .Collapsible__contentOuter {
                width: 100%;

                .Collapsible__contentInner {
                    width: 100%;
                    padding: 0 20px 20px 20px;
                }
            }
        }
    }
}
